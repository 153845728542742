import React from 'react';
import Layout from '../layouts/layout'

function Terms() {
    return (
        <div>
            <Layout headerClass="bg-black h-[122px] relative">
                <div className='flex justify-center'>

                    <div className="container info-content py-20">



                    Disclaimer
With the improvements and expansion of AI-powered tools, we want to be as transparent as possible as to what uses and tools are acceptable for freelancers when creating content on The Urban Writers Platform.

Freelancers on The Urban Writers Platform are permitted to use the Dibbly-powered AI tool, KIP, on projects only under the condition that they have received express permission from the customer to do so. KIP is a chatbot developed by Dibbly that is designed to assist writers in the creative writing process. While KIP can provide helpful tips and guidance, it is important to remember that it is not a substitute for professional writing or editing.

Freelancers are expected to create original written, visual, or voiced content as contracted by their customer. The use of any AI tools other than KIP, or the use of tools for the production of content without modification by the freelancer, or any use of AI in an order without express permission from the customer, is prohibited. It is important that writers verify the relevance and reliability of any sources and citations provided by AI before using them in their work. All AI-generated content must be modified by the freelancers to ensure veracity, appropriateness, accessibility, and originality.

Allowing freelancers the use of KIP is optional, however anyone who chooses to use KIP accepts that:

Any content generated by our AI systems will be used for machine learning purposes to improve the accuracy, quality, and relevance of our content over time.
We protect the privacy of our users and ensure that any data used to train our AI systems is collected with user consent and used in accordance with applicable laws and regulations.
Third Party AI detection Tools
The use of AI detection tools by third party suppliers does not guarantee accuracy or completeness of any content, and no representation is made as to its accuracy, completeness, or reliability for any content that is produced on the platform, and checked by external AI detectors. The use of AI detection tools by third party suppliers does not constitute the need to revise the content produced. All content produced will follow our conventional revision policies. Our revision policy does not allow requesting changes to the document based on third party AI detection tools used, or based on the speculation of AI tools being used in the content creation process. While these tools may prove useful in certain contexts, they are not always accurate and writers cannot be asked to make changes in accordance with their findings.

Our revision policy can be reviewed here: https://theurbanwriters.com/pages/refund-and-revision-policy. Please note this policy stands as is and no revisions can be offered or requested based on external AI detection tools used, or any speculation on whether AI tools were used in the content creation process.

In the event that customers suspect the misuse of AI tools by freelancers, or the unapproved use of these tools, our Freelancer Success team will conduct a thorough review and present the customer with a report of the findings. Based on these findings, the team will provide the customer with options to move forward.

Policy
At Dibbly, we believe that the ethical use of artificial intelligence (AI) is essential for creating valuable and meaningful content for our users. Our AI algorithms are designed to generate content that is informative, helpful, and beneficial for all our user.

We take the following steps to ensure that our use of AI for generated content is ethical:
Transparency: We are transparent about how our AI-generated content works, and we clearly label any content produced by our AI systems.
Fairness: We ensure that our AI algorithms and generated content are free from bias and do not promote any form of discrimination.
Accuracy: We make every effort to ensure that the content produced by our AI systems is accurate and based on reliable sources of information.
Privacy: We protect the privacy of our users and ensure that any data used to train our AI systems is collected with user consent and used in accordance with applicable laws and regulations.
Accountability: We take responsibility for the content generated by our AI systems, and we regularly review and audit our systems to ensure they meet our ethical standards.
At Dibbly, we are committed to using AI for generating content in a responsible and ethical manner. We believe that the responsible use of AI holds great promise for delivering valuable content to our users, and we will continue to innovate and improve our AI systems with this goal in mind.

Acceptable Uses
Freelancers can use AI tools to:

shape content, generate ideas for characters, conflict, setting, etc.,
refine ideas, tailor phrasing and expression, correct spelling/grammar, etc.
check consistency, improve parallelism, tailor phrasing and expression, etc.
conduct research and gather source materials.
Consequences of Misuse
Freelancers are prohibited from using sensitive or confidential customer information when prompting AI tools. Sensitive or confidential information includes, but is not limited to, personal details, anecdotes, personal stories, locations, names, pen names, profile images, and publishing information.

Freelancers are prohibited from using AI tools on any customer orders without express permission from the customer.

Freelancers are prohibited from running any content written for TUW through any third party AI detection tools.

Freelancers who breach our policy either by using banned AI tools for content creation or by improperly utilizing KIP, will be removed from The Urban Writers Platform. The freelancer's account will be deactivated.

Please note that as AI is an emerging technology, Dibbly Inc. reserves the right to revise this policy to reflect trends and concerns at any time. 

Any content generated by our AI systems will be used for machine learning purposes to improve the accuracy, quality, and relevance of our content over time.





                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Terms;